<template>
  <div>
    <h4>{{ i18n['profile-member-info'].tcMemberDetails }}</h4>
    <div class="specs">
      <p>
        <strong>{{ i18n['profile-member-info'].tcMemberID }}:</strong>
        {{ profile.member_number }}
      </p>
      <p>
        <strong>{{ i18n['profile-member-info'].tcCampName }}:</strong>
        {{ profile.camp_name }}
      </p>
      <p v-if="
        iCanSee(secured_link_view_controls.view_member_church_membership_link)
      ">
        <strong>{{ i18n['profile-member-info'].tcChurchMembership }}:</strong>
        <br />
        {{ profile.church_name }}
      </p>
      <p>
        <strong>{{ i18n['profile-member-info'].tcOtherInformation }}:</strong>
        <br />
        <span v-if="(!profile.comment || profile.comment.length < 150)" v-html="profile.comment"></span>
        <span v-else v-html="`${profile.comment.substring(0, 150)}...`"></span>
      </p>
      <router-link v-if="profile.member_number !== ''" :to="{ name: 'member-details' }" class="btn btn-primary mt-2">{{
          i18n['profile-member-info'].tcViewMore
      }}</router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
  name: 'profile-member-info',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'profile-member-info': {
            tcCampName: 'Camp Name',
            tcChurchMembership: 'Church Membership',
            tcMemberDetails: 'Member Details',
            tcMemberID: 'Member ID',
            tcOtherInformation: 'Other Information',
            tcViewMore: 'View More',
          },
        }
      },
    },
    profile: {
      member_number: {
        type: String,
        default: '',
      },
      camp_name: {
        type: String,
        default: '',
      },
      church_name: {
        type: String,
        default: '',
      },
      comment: {
        type: String,
        default: '',
      },
    },
  },
  data() {
    return {
      secured_link_view_controls: {
        view_member_church_membership_link:
          '60a420bc-1b3c-458c-b686-7b9338f2b041',
      },
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>
